<template>
  <div class="page">
    <div class="content">
      <div style="margin-top: 20px;">
        <div style="font-size: 30px; font-family: 黑体; color: rgb(255, 0, 0);">版本更新说明：</div>
        <div style="font-size: 20px; font-family: 黑体; margin-top: 5px;">因为手机品牌，机型，系统不同，可能会出现一定的适配问题</div>
        <div style="font-size: 20px; color: rgb(255, 0, 0); margin-top: 10px;">以下为更新后，可能出现的问题的说明：</div>
      </div>
      <div style="margin-bottom: 0;line-height: 100%;font-size: 20px;font-family: 黑体;margin-top: 10px;">
        <div>①模拟定位/模拟路线不生效，或者模拟位置，请清理后台，重新打开多开的应用分身，查看是否模拟成功</div>
        <div style="margin-top: 20px;">
          <video
              src="https://douzhuanduokai.oss-cn-beijing.aliyuncs.com/video/wtsp1.mp4"
              controls
              preload="auto"
              webkit-playsinline="true"
              playsinline="true"
              x-webkit-airplay="allow"
              x5-video-player-type="h5"
              x5-video-player-fullscreen="true"
              x5-video-orientation="landscape"
              style="object-fit:fill;width: 100%;"
              poster="https://example.com/path/to/your/poster-image.jpg">
          </video>
        </div>
      </div>
      <div style="margin-bottom: 0;line-height: 100%;font-size: 20px;font-family: 黑体;margin-top: 10px;">
        <div>②收藏夹功能正在维护中，如果更新新版本，请在更新前进入收藏夹进行云备份数据，操作方法请参考视频</div>
        <div style="margin-top: 20px;">
          <video
              src="https://douzhuanduokai.oss-cn-beijing.aliyuncs.com/video/wtsp2.mp4"
              controls
              preload="auto"
              webkit-playsinline="true"
              playsinline="true"
              x-webkit-airplay="allow"
              x5-video-player-type="h5"
              x5-video-player-fullscreen="true"
              x5-video-orientation="landscape"
              style="object-fit:fill;width: 100%;"
              poster="https://example.com/path/to/your/poster-image.jpg">
          </video>
        </div>
      </div>
      <div style="margin-bottom: 0;line-height: 100%;font-size: 20px;font-family: 黑体;margin-top: 10px;">
        <div>③重命名功能，请在安装独立分身前修改应用名称，独立分身安装后不支持重命名</div>
        <div style="margin-top: 20px;">
          <video
              src="https://douzhuanduokai.oss-cn-beijing.aliyuncs.com/video/wtsp3.mp4"
              controls
              preload="auto"
              webkit-playsinline="true"
              playsinline="true"
              x-webkit-airplay="allow"
              x5-video-player-type="h5"
              x5-video-player-fullscreen="true"
              x5-video-orientation="landscape"
              style="object-fit:fill;width: 100%;"
              poster="https://example.com/path/to/your/poster-image.jpg">
          </video>
        </div>
      </div>
      <div style="margin-bottom: 0;line-height: 100%;font-size: 20px;font-family: 黑体;margin-top: 10px;">
        <div>④因为手机品牌，系统版本问题，可能出现旧版本功能正常使用而新版本功能使用异常的情况，请卸载新版本，重新安装旧版本。</div>
        <div style="margin-top: 20px;">
          <video
              src="https://douzhuanduokai.oss-cn-beijing.aliyuncs.com/video/wtsp4.mp4"
              controls
              preload="auto"
              webkit-playsinline="true"
              playsinline="true"
              x-webkit-airplay="allow"
              x5-video-player-type="h5"
              x5-video-player-fullscreen="true"
              x5-video-orientation="landscape"
              style="object-fit:fill;width: 100%;"
              poster="https://example.com/path/to/your/poster-image.jpg">
          </video>
        </div>
      </div>
      <div style="font-size: 20px;text-indent: 16px;margin-top: 20px;line-height: 100%;color: rgb(255, 0, 0);">
        注意：本次更新主要解决多开的应用闪退黑屏等应用不稳定的问题，如果您遇到的问题以上方法无法解决或者您遇到其它的使用问题，请留言反馈或者联系客服，我们会尽快解决您的问题
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
    return {

    }
  },
  components: {
  }
}
</script>
<style lang="less" scoped>

</style>